import React from "react";
import { useState, useEffect } from "react";

import axios from "axios";

function HomePage() {
  const [dataNews, setDataNews] = useState([]);
  const [dataBeteGedam, setDataBeteGedam] = useState([]);
  const [dataAwedemihret, setDataAwedmihret] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (selectedPage = 0) => {
    try {
        const response = await axios.get(`https://admin.betyared.org/api/news/?page=${selectedPage + 1}`);
        setDataNews(response.data.results.slice(-2));
        
        
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = (selected) => {
    fetchData(selected.selected);
  };
  return (
    <div className="w-full">
      <h1 className="text-3xl"><a className=" hover:text-primary" href="/news">ዜና</a></h1>
      
      <div class="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5">
      
    {dataNews.map((da) => (<div class="rounded overflow-hidden shadow-lg">
      <img class="w-full h-40 md:h-60" src= {da.image} alt={da.title}/>
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2"><a  className='hover:text-primary' href='/news'>{da.title}</a></div>
      </div>
       </div>))}
  </div>
  </div>
    
  );
  }
  

export default HomePage;
