import React, { useState, useEffect } from "react";
import axios from "axios";

import classesItem from "../components/lists/Item.module.css";
import classes from "../components/lists/List.module.css";

import Card from "../components/lists/ui/Card";

const News = () => {
  const [dataNews, setDataNews] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://admin.betyared.org/api/news/?page=${currentPage}`
      );
      setDataNews(response.data.results);
      setPageCount(response.data.total_pages);
    } catch (error) {
      console.log(error);
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="w-full">
      <h1 className="text-3xl">ዜና</h1>
      <ul className={classes.list}>
        {dataNews.map((meetup) => (
          <li className={classesItem.item}>
            {}
            <Card>
              <h3 className="flex mr-4 text-3xl justify-start">
                {meetup.title}
              </h3>
              <div className="rounded bg-white border-gray-200 shadow-md overflow-hidden relative">
                <div className="classesItem.image">
                  <img
                    src={meetup.image}
                    alt={meetup.title}
                    className="w-full"
                  />
                </div>
                <div className="absolute flex grid-cols-2 top-0 right-0 ml-2 p-2 mt-2 text-white bg-primary text-xs uppercase font-bold rounded-full">
                  <span className="pl-2">Photo By:</span>
                  <span className="pl-2">{meetup.photoBy}</span>
                  <span className="pl-2">Post By:</span>
                  <span className="pl-2">{meetup.postBy}</span>
                  <span className="pl-2">Date:</span>
                  <span className="ml-2">
                    {" "}
                    {meetup.created_at.slice(0, 10)}
                  </span>
                </div>
              </div>

              <p className="text-center p-1 mt-2">{meetup.detail}</p>
            </Card>
          </li>
        ))}
      </ul>
      <div className="flex justify-evenly">
        <button
          className="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={previousPage}
          disabled={currentPage === 1}
        >
          ቀፃሊ
        </button>
        <button
          className="flex items-center justify-center px-4 h-10 ml-3 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={nextPage}
          disabled={currentPage === pageCount}
        >
          ሕሉፍ
        </button>
      </div>
    </div>
  );
};

export default News;
